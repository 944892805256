import React, { useState, useEffect } from 'react';
import { Box, Card, CardHeader, Chip} from '@mui/material';
import { SnackbarState } from 'src/models/snackbar';
import useAuth from 'src/hooks/useAuth';
import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridToolbar, GridToolbarContainer, GridToolbarExport, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { Padding } from '@mui/icons-material';

const GreenDot = (value: any) => {
  const dotStyle = {
    height: '20px',
    width: '20px',
    backgroundColor: 'green',
    borderRadius: '50%',
    display: 'inline-block',
  };

  return <span>{value}<span style={dotStyle}></span></span>;
};

const renderBlockchainCell = (params: GridRenderCellParams<any, string>) => {
  return params.value;
};

const renderMethodCell = (params: GridRenderCellParams<any, string>) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
     <Chip key={params.value} label={String(params.value)} />
  </Box>
  );
};

const renderTimeCell = (params: GridRenderCellParams<any, string>, size: number = 14, color: string = 'green') => {
  return (
    <span><svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" />
    <line x1="12" y1="6" x2="12" y2="12" stroke={color} strokeWidth="2" />
    <line x1="12" y1="12" x2="16" y2="14" stroke={color} strokeWidth="2" />
  </svg>&nbsp;{params.value} ms</span>
  );
};

const renderStatusCell = (params: GridRenderCellParams<any, string>) => {
  const dotStyle = {
    height: '10px',
    width: '10px',
    backgroundColor: Number(params.value) === 200 ? 'green' : Number(params.value) >= 500 ? 'red' : 'orange',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '5px'
  };

  return <span>{params.value}<span style={dotStyle}></span></span>;
  //return (<GreenDot value={params.value}/>)
};
const columns: GridColDef[] = [
  {
    field: 'blockchain',
    headerName: 'Network',
    width: 160,
    type: 'string',
    hideable: false,
    valueGetter: (value, row) => {
      return `${row.chain.toUpperCase() || ''} ${row.network.toUpperCase() || ''}`;
    }
  },
  { field: 'chain', headerName: 'Blockchain', width: 100, type: 'string' },
  { field: 'network', headerName: 'Network', width: 80, type: 'string' },
  {
    field: 'method',
    headerName: 'Method',
    width: 200,
    type: 'string',
    renderCell: renderMethodCell
  },
  {
    field: 'code',
    headerName: 'Status',
    width: 75,
    type: 'number',
    renderCell: renderStatusCell
    //valueFormatter: (value) => `${value}`
  },
  { field: 'cacheHit', headerName: 'Cache Hit', width: 80, type: 'boolean' },
  {
    field: 'ms',
    headerName: 'Time',
    width: 125,
    type: 'number',
    renderCell: renderTimeCell
    //valueFormatter: (value) => `${value} ms`
  },
  { field: 'retries', headerName: 'Retries', width: 75, type: 'number' },
  {
    field: 'reqKB',
    headerName: 'Req',
    width: 75,
    type: 'number',
    valueFormatter: (value) => `${value} kb`
  },
  {
    field: 'resKB',
    headerName: 'Res',
    width: 75,
    type: 'number',
    valueFormatter: (value) => `${value} kb`
  },
  {
    field: 'workerRegion',
    headerName: 'Region',
    width: 120,
    type: 'string',
    valueFormatter: (value) => {
      if (value === 'us-east-1') {
        return 'N. Virginia';
      }
      return value;
    }
  },
  {
    field: 'datetime',
    headerName: 'Sent',
    width: 200,
    type: 'dateTime',
    valueFormatter: (value) =>
      moment(value).format('MMM Do YYYY, h:mm:ss.SSS A')
  }
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function EntriesTable(props) {
  const { hasPermission } = useAuth();
  const [rows] = useState<GridRowsProp>(props.rows);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState<SnackbarState>({
    msg: '',
    severity: 'success'
  });
  const [rowSelectionModel, setRowSelectionModel] =
  React.useState<GridRowSelectionModel>([]);
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Card>
      <CardHeader title={`RPC Request`} />
      <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        columnVisibilityModel={{
          chain: false,
          network: false,
          blockchain: false,
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        // disableColumnFilter
        // disableColumnSelector
        // disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
    </Card>
  );
}

export default EntriesTable;
