import { Card, CardHeader, Grid } from '@mui/material';
import {
  LineChart,
  lineElementClasses,
  markElementClasses
} from '@mui/x-charts/LineChart';

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const bData = [2200, 498, 2486, 4278, 1435, 7277, 2473];
const xLabels = [
  '5/26/24',
  '5/27/24',
  '5/28/24',
  '5/29/24',
  '5/30/24',
  '5/31/24',
  '6/01/24'
];

function RequestDistributionTrend() {
  const user = {
    'account-id': '',
    chain: '',
    network: ''
  };
  return (
    <Card>
      <CardHeader title={`Request Distribution`} />
      <LineChart
        width={850}
        height={200}
        colors={['#1D476D', '#3BA5F3', '#9DA2A8', 'green']}
        series={[
          { data: pData, label: 'QuickNode', id: 'pvId' },
          { data: uData, label: 'Custom', id: 'uvId' },
          { data: bData, label: 'Base', id: 'bvId' }
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        sx={{
          [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
            strokeWidth: 1
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '5 5'
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '3 4 5 2'
          },
          [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]:
            {
              fill: '#fff'
            },
          [`& .${markElementClasses.highlighted}`]: {
            stroke: 'none'
          }
        }}
      />
    </Card>
  );
}
export default RequestDistributionTrend;
