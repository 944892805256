import Endpoint from 'src/class/Endpoint';
import { User } from 'src/models/user';
import StripeUtils from 'src/models/StripeUtils';
import { StripeProduct } from 'src/interfaces/StripeProduct';
import { StripePlan } from 'src/interfaces/StripePlan';
import { StripeCustomer } from 'src/interfaces/StripeCustomer';
import { StripeSubscription } from 'src/interfaces/StripeSubscription';

interface Policy {
  read: Array<string>;
  write: Array<string>;
}

interface PolicyResponse {
  policy: Policy;
  valid: boolean;
  status: string;
}

export class AccountAPI {
  private endpoint: Endpoint;

  private user: User; 

  private host: string; 

  private refreshJwtToken: string;

  private 'x-backpac-token': string | undefined;

  private logoutUser: any

  private stripeUtils: StripeUtils;

  constructor(user: User, logoutUser: any | undefined, host: string) {
    this.endpoint = new Endpoint();
    this.logoutUser = logoutUser;
    this.user = user;
    this.host = host;
    if(user !== null){
      this['x-backpac-token'] = this.user['x-backpac-token'];
      this.refreshJwtToken = typeof this.user.refreshJwtToken === "undefined" ? this.user.jwt : this.user.refreshJwtToken;
    }
    const plans: Array<StripePlan> = [{ name: 'pro', product: 'Plans' }];
    this.stripeUtils = new StripeUtils(this.host, plans);
  }



  public subscription = async () => {
    try {
      const result = await this.endpoint.getApiClientWithAdditionalParams(
        '/dashboard/plans',
        {
          headers: { 'x-backpac-token': this.refreshJwtToken, host: "desktop" },
        }
      );

      if(typeof result.data !== "undefined" && result.data.valid === false){
        if(result.data.logout === true) {
          this.logoutUser();
        }
      }

      return result
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public confirmSubscription = async () => {
    try {
      const result = await this.endpoint.getApiClientWithAdditionalParams(
        '/dashboard/plans',
        {
          headers: { 'x-backpac-token': this.refreshJwtToken, host: "desktop" },
        }
      );

      if(typeof result.data !== "undefined" && result.data.valid === false){
        if(result.data.logout === true) {
          this.logoutUser();
        }
      }

      return result
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public get = async () => {
    try {
      const refreshJwtToken = typeof this.user.refreshJwtToken === "undefined" ? this.user.jwt : this.user.refreshJwtToken;
      return await this.endpoint.getApiClientWithAdditionalParams(
        `/dashboard/plan/`,
        {
          headers: { 'x-backpac-token': refreshJwtToken, host: this.host },
        }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public plan = async () => {
    try {
      const refreshJwtToken = typeof this.user.refreshJwtToken === "undefined" ? this.user.jwt : this.user.refreshJwtToken;
      return await this.endpoint.getApiClientWithAdditionalParams(
        `/dashboard/plan/rbac`,
        {
          headers: { 'x-backpac-token': refreshJwtToken, host: this.host },
        }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public policy = async ():Promise<{policy: Policy, status: string, valid: boolean}> => {
    try {
      const refreshJwtToken = typeof this.user.refreshJwtToken === "undefined" ? this.user.jwt : this.user.refreshJwtToken;
      const { data } = await this.endpoint.getApiClientWithAdditionalParams(
        `/dashboard/account/policy`,
        {
          headers: { 'x-backpac-token': refreshJwtToken, host: this.host },
        }
      );
      const {policy, status, valid} = data;
      return {policy, status, valid};
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public trialPolicy = async ():Promise<{policy: Policy, status: string, valid: boolean}> => {
    try {
      const refreshJwtToken = typeof this.user.refreshJwtToken === "undefined" ? this.user.jwt : this.user.refreshJwtToken;
      const { data } = await this.endpoint.getApiClientWithAdditionalParams(
        `/dashboard/account/policy`,
        {
          headers: { 'x-backpac-token': refreshJwtToken, host: this.host },
        }
      );
      const {policy, status, valid} = data;
      return {policy, status, valid};
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  private getObjectById(array, id) {
    // Find the object with the matching id
    const foundObject = array.find((obj) => obj.id === id);

    // Return the found object or null if not found
    return foundObject || null;
  }

  public retrieveSession = async (
    session_id: string,
    products: Array<StripeProduct>
  ) => {
    
    const sessionItems = await this.stripeUtils.retrieveSessionLineItems(
      session_id
    );
    const product: StripeProduct = this.getObjectById(
      products,
      sessionItems[0].price.product
    );
    const plan = product.metadata.plan;
    const customers: Array<StripeCustomer> =
      await this.stripeUtils.searchCustomerByEmail(this.user.email);

    //We need to check if the customers data was recorded to the account

    //Update the JWT for the user so that it has the correct plan

    //Update the Account with the plan and CustomerId

  };

  public retrieveSubscriptions = async (id: string) => {
    const subscriptions: Array<StripeSubscription> =
      await this.stripeUtils.listSubscriptions(id);
    const baseSubs = [];
    //Loop the subs and get the products
    for (const subscription of subscriptions) {
      console.log(subscription);
      // const prod: StripeProduct = await _StripeUtils.retrieveProduct(
      //   subscription.plan.product
      // );
      // subscription.product_details = prod;
      // if (typeof subscription.product_details.metadata.plan !== 'undefined') {
      //   setMainSubscription(subscription);
      // } else {
      //   baseSubs.push(subscription);
      // }
    }
  
  };

}
  