import React, { useState, useEffect } from 'react';
import { Card, CardHeader} from '@mui/material';
import { SnackbarState } from 'src/models/snackbar';
import useAuth from 'src/hooks/useAuth';
import {
  LineChart,
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';

const xLabels = [
  '2024-05-30',
  '2024-05-31',
  '2024-06-01'
];

function ResponseTime(props) {
  const { hasPermission } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState<SnackbarState>({
    msg: '',
    severity: 'success'
  });

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format;

  useEffect(() => {}, []);

  return (
    <Card>
      <CardHeader title={`Response Time`} />
      <LineChart
        width={600}
        height={300}
        colors={['#1D476D', '#3BA5F3', '#9DA2A8', 'green']}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 10,
            itemMarkWidth: 20,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 10,
            hidden: true
          },
        }}
        series={[
          {
            data: [170, 179, 176],
            label: 'eth_getTransactionByHash',
            id: 'eth_getTransactionByHashId',
            showMark: false,
          },
          {
            data: [189, 123, 166],
            label: 'eth_getTransactionCount',
            id: 'eth_getTransactionCountId',
            showMark: false,
          },
          {
            data: [160, 144, 154],
            label: 'eth_getTransactionReceipt',
            id: 'eth_getTransactionReceiptId',
            showMark: false,
          },
          {
            data: [182, 135, 117],
            label: 'eth_getBalance',
            id: 'eth_getBalanceId',
            showMark: false,
          },
          {
            data: [214, 234, 191],
            label: 'eth_blockNumber',
            id: 'eth_blockNumberId',
            showMark: false,
          },
          // {
          //   data: [344, 344, 498],
          //   label: 'eth_getBlockByNumber',
          //   id: 'eth_getBlockByNumberId',
          //   showMark: false,
          // },
          // {
          //   data: [157, 161, 172],
          //   label: 'eth_estimateGas',
          //   id: 'eth_estimateGasId',
          //   showMark: false,
          // },
          // {
          //   data: [258, 322, 456],
          //   label: 'batch',
          //   id: 'batchId',
          //   showMark: false,
          // }
        ]}
        xAxis={[{ scaleType: 'band', data: xLabels }]}
        sx={{
          [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
            strokeWidth: 1,
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '5 5',
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '3 4 5 2',
          },
          [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
            fill: '#fff',
          },
          [`& .${markElementClasses.highlighted}`]: {
            stroke: 'none',
          },
        }}
      />
    </Card>
  );
}

export default ResponseTime;
