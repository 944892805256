import { useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";
export const useLocalStorage = () => {
  const [value, setValue] = useState<string | null>(null);

  const setItem = (key: string, value: string) => {
    //localStorage.setItem(key, value);
    secureLocalStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = (key: string) => {
    //const value = localStorage.getItem(key);
    const value: any = secureLocalStorage.getItem(key);
    setValue(value);
    return value;
  };

  const removeItem = (key: string) => {
    secureLocalStorage.removeItem(key);
    setValue(null);
  };
  function setWithExpiry(key, value, ttl) {
    try{
      const now = new Date()
  
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      secureLocalStorage.setItem(key, JSON.stringify(item))
    }catch(e){
      console.error(e);
    }
  }
  
  function getWithExpiry(key) {
    try {
      const itemStr: any = secureLocalStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        secureLocalStorage.removeItem(key)
        return null
      }
      return item.value
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return { value, setItem, getItem, removeItem, setWithExpiry, getWithExpiry };
};
