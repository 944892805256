import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../images/backpac.svg";
import logoLightImg from "../../images/backpac-icon.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  width?: string;
  height?: string;
}

const LogoImg: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
  width = "200px",
  height = "100px"
}) => {
  return (
    <img
    className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
    src={img}
    alt="Logo"
    width={width}
    height={height}
  />
    // <Link
    //   to="/"
    //   className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    // >
    //   <img
    //       className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
    //       src={img}
    //       alt="Logo"
    //       width={width}
    //       height={height}
    //     />
    // </Link>
  );
};

export default LogoImg;
