import { Typography,  Grid } from '@mui/material';



function PageHeader() {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };

  return (
    <Grid container alignItems="right">
      <Grid item>
      <Typography variant="h1">Observability Dashboard</Typography>
      </Grid>
      <Grid item>
        &nbsp;
      </Grid>
    </Grid>
  );
}

export default PageHeader;
