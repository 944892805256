import {
  Box,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import LogoImg from '../Logo/Logo';
const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);
const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);


function Logo({ width, height }: { width?: string | undefined, height?: string | undefined }) {
  return (
      <LogoWrapper to="/">
          <LogoSignWrapper>
            <LogoImg width={width} height={height}/>
        </LogoSignWrapper>
      </LogoWrapper>
  );
}
export default Logo;
