import React from 'react';

interface LoadingProps {
  isLoading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isLoading, children }) => {
  return (
    <div>
      {isLoading ? <div>Loading...</div> : children}
    </div>
  );
};

export default Loading;
