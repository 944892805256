import axios from 'axios';
import { SlackFeedbackMessage } from 'src/interfaces/SlackFeedback';
class SlackAPI {
  /**
   * addAccount - add account to RPC LB accounts slack channel
   * @param {string} id     the identifier for the account
   * @param {string} chain  the blockchain
   * @param {number} network the network on the blockchain selected
   */
  public static async feedback(SlackFeedbackMessage: SlackFeedbackMessage): Promise<{ success: boolean; res?: any }> {
    try {
      const res = await axios.post(
        process.env.REACT_APP_SLACK_FEEDBACK_CHANNEL,
        JSON.stringify({
          text: `New RPC LB Feedback: (${SlackFeedbackMessage.title}) From: ${SlackFeedbackMessage.author_name}  (${SlackFeedbackMessage.email}) | ${SlackFeedbackMessage.org}) | ${SlackFeedbackMessage.fallback} - ${SlackFeedbackMessage.text} | ${SlackFeedbackMessage.title_link}`
        }),
        {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              //console.log({data, headers})
              //delete headers.post["Content-Type"]
              return data;
            }
          ]
        }
      );

      if (res.status === 200) {
        return { success: true, res };
      } else {
        return { success: false, res };
      }
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  }

  /**
   * updateRating - send a rating for a completion in slack
   * @param {string} email
   * @param {string} completionid
   * @param {number} rating
   * @param {string} feedback
   */
  public static async updateRating(
    email: string,
    completionid: string,
    rating: number,
    feedback: string
  ): Promise<{ success: boolean; res?: any }> {
    try {
      const res = await axios.post(
        process.env.REACT_APP_SLACK_RATING_WEBHOOK,
        JSON.stringify({
          text: `Email Address: ${email}\n Completion: ${completionid} \nRating: ${rating}\n Feedback: ${feedback}`,
        }),
        {
          withCredentials: false,
          transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          }],
        }
      );

      if (res.status === 200) {
        return { success: true, res };
      } else {
        return { success: false, res };
      }
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  }

  /**
   * contactUs - send contact us details to slack channel
   * @param {string} email
   * @param {string} name
   * @param {string} subject
   * @param {string} message
   */
  public static async contactUs(
    email: string,
    name: string,
    subject: string,
    message: string
  ): Promise<{ success: boolean; res?: any }> {
    try {
      const res = await axios.post(
        process.env.REACT_APP_SLACK_CONTACTUS_WEBHOOK,
        JSON.stringify({
          text: `Email Address: ${email}\n Name: ${name} \nSubject: ${subject}\n Message: ${message}`,
        }),
        {
          withCredentials: false,
          transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          }],
        }
      );

      if (res.status === 200) {
        return { success: true, res };
      } else {
        return { success: false, res };
      }
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  }
}

export default SlackAPI;
