import * as CryptoJS from 'crypto-js';

export class CryptoUtils {
  private static encryptionKey = 'your-encryption-key'; // Change this to your encryption key

  static encrypt(data: string): string {
    const encryptedData = CryptoJS.AES.encrypt(data, this.encryptionKey).toString();
    return encryptedData;
  }

  static decrypt(encryptedData: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  static hash(data: string): string {
    const hashedData = CryptoJS.SHA256(data).toString();
    return hashedData;
  }

  static compareHash(data: string, hashedData: string): boolean {
    const newDataHash = this.hash(data);
    return newDataHash === hashedData;
  }
}