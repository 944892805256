import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Grid } from '@mui/material';
import { SnackbarState } from 'src/models/snackbar';
import useAuth from 'src/hooks/useAuth';
import { BarChart } from '@mui/x-charts/BarChart';
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const amtData = [2400, 2210, 2290, 2000, 2181, 2500, 2100];

const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G'
];

function MethodUsage(props) {
  const { hasPermission } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState<SnackbarState>({
    msg: '',
    severity: 'success'
  });
  const [methodUsage, setMethodUsage] = useState<any>(props.data ? props.data : {data: [], labels: []});

  useEffect(() => {
    if(props.data){
      setMethodUsage(props.data)
    }
    console.log(methodUsage)
  }, [props]);

  return (
    <Card>
      <CardHeader title={`Method Usage`} />
      <BarChart
        slotProps={{
          // Custom loading message
          loadingOverlay: { message: 'Data should be available soon.' },
          // Custom message for empty chart
          noDataOverlay: { message: 'Select some data to display.' },
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 10,
            itemMarkWidth: 20,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 10,
            hidden: true
          },
        }}
        width={500}
        height={300}
        colors={['#1D476D', '#3BA5F3', '#9DA2A8', 'green']}
        series={methodUsage.data}
        xAxis={[{ data: methodUsage.labels, scaleType: 'band' }]}
      />
    </Card>
  );
}

export default MethodUsage;
