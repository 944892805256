import { useState, useEffect } from 'react';
import axios from 'axios';
import { User } from 'src/models/user';
import { useLocalStorage } from "./useLocalStorage";
import { useUser } from "./useUser";
interface UseFindUserResult {
  user: User | null;
  isLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>; // Define setUser function
}

export default function useFindUser(): UseFindUserResult {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);
  const { getItem, getWithExpiry } = useLocalStorage();
  const { addUser } = useUser();

  useEffect(() => {
    async function findUser() {
      try {
        const user = getWithExpiry("backpac-rpc-lb-user");
        if (user) {
          const parsedUser = JSON.parse(user);
          console.log('expiresAt', parsedUser.expiresAt);
          addUser(JSON.parse(user));
        }
        setLoading(false);
        setUser(JSON.parse(user));
      } catch (err) {
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    }
    findUser();
  }, []);

  return {
    user,
    isLoading,
    setUser // Include the setUser function in the returned result
  };
}
