import router from 'src/router';// Import your router configuration
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import useFindUser from './hooks/useFindUser';
import { UserContext } from './hooks/UserContext';
import SuspenseLoader from './components/SuspenseLoader';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
function App() {
  const { user, setUser, isLoading } = useFindUser();
  //const { user, setUser, isLoading } = useContext(UserContext);
  const content = useRoutes(router);

  if (isLoading) {
    return <SuspenseLoader />;
  }


  return (
    <UserContext.Provider value={{ user, setUser, isLoading }}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
          <NotificationContainer />
        </LocalizationProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
