import React, { useState, useEffect } from 'react';
import { Card, CardHeader } from '@mui/material';
import { SnackbarState } from 'src/models/snackbar';
import useAuth from 'src/hooks/useAuth';
import {
    GaugeContainer,
    GaugeValueArc,
    GaugeReferenceArc,
    useGaugeState,
    gaugeClasses, 
    Gauge
  } from '@mui/x-charts/Gauge';

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  
    if (valueAngle === null) {
      // No value to display
      return null;
    }
  
    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="#3BA5F3" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="#3BA5F3"
          strokeWidth={3}
        />
      </g>
    );
  }

function RequestRate(props) {
  const { hasPermission } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState<SnackbarState>({
    msg: '',
    severity: 'success'
  });

  useEffect(() => {}, []);

  return (
    <Card>
      <CardHeader title={`Request Rate`} />
      <Gauge
        width={220}
        height={200}
        value={320}
        valueMax={500}
        valueMin={0}
        startAngle={-110}
        endAngle={110}
        cornerRadius="50%"
        margin={{
          left: 50,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        sx={{
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 22,
            transform: 'translate(0px, 0px)'
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: '#1D476D',
          },
        }}
        text={({ value, valueMax }) => `${value} / ${valueMax}`}
      >
        <GaugeReferenceArc />
        <GaugeValueArc />
        <GaugePointer />
      </Gauge>
    </Card>
  );
}

export default RequestRate;
