import { useContext } from "react";
import { UserContext } from "./UserContext";
import { useLocalStorage } from "./useLocalStorage";
import { User } from 'src/models/user';

export const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  const { setItem, removeItem, setWithExpiry } = useLocalStorage();
  const addUser = (user: User) => {
    setUser(user);
    setWithExpiry("backpac-rpc-lb-user", JSON.stringify(user), 3600000);
  };

  const removeUser = () => {
    setUser(null);
    removeItem("backpac-rpc-lb-user");
  };

  return { user, addUser, removeUser };
};