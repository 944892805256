import { createContext} from 'react';
import { User } from 'src/models/user';

// export const UserContext = createContext<User>({
//   email: undefined,
//   name: undefined,
//   iat: undefined,
//   jti: undefined,
//   organization_name: undefined,
//   account_id: undefined,
//   plan: undefined,
//   jwt: undefined,
//   isLoading: false
// });

interface AuthContext {
    user: User | null;
    isLoading: boolean;
    setUser: React.Dispatch<React.SetStateAction<User | null>>; // Define setUser function
  }

  export const UserContext = createContext<AuthContext>({
    user: null,
    isLoading: false,
    setUser: () => {},
  });

//export const UserContext = createContext(null);