import { FC, ReactNode, useContext } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SlackFeedback, { themes } from 'react-slack-feedback'
import Sidebar from './Sidebar';
import Header from './Header';
import SlackAPI from 'src/class/SlackAPI';
import { UserContext } from 'src/hooks/UserContext';
import { SlackFeedbackMessage } from 'src/interfaces/SlackFeedback';
import BackpacIcon from 'src/components/Logo/BackpacIcon';
interface SidebarLayoutProps {
  children?: ReactNode;
}


const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { user } = useContext(UserContext);

  async function sendToServer(payload: any, success: any, error: any) {
    let slackFeedbackMessage: SlackFeedbackMessage = payload.attachments[0];
    slackFeedbackMessage.email = user.email;
    slackFeedbackMessage.name = user.name;
    slackFeedbackMessage.author_name = user.name;
    slackFeedbackMessage.org = user.organization_name;
    slackFeedbackMessage.plan = user.plan;
    const send = await SlackAPI.feedback(slackFeedbackMessage);

    if (send.success === true) {
      success();
    } else {
      console.error(send.res);
      error();
    }
  }

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
        <SlackFeedback
          channel="#feedback"
          defaultSelectedType="feature"
          showChannel={false}
          icon={() => <BackpacIcon />}
          theme={themes.dark} // (optional) See src/themes/default for default theme
          user="Slack Feedback" // The logged in user (default = "Unknown User")
          onSubmit={(payload, success, error) =>
            sendToServer(payload, success, error)
          }
        />
      </Box>
    </>
  );
};

export default SidebarLayout;
