import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Container, FormHelperText, Grid } from '@mui/material';
import Footer from 'src/components/Footer';
import ResponseTime from './sections/ResponseTime';
import MethodUsage from './sections/MethodUsage';
import EntriesTable from './sections/EntriesTable';
import RequestDistributionTrend from './sections/RequestDistributionTrend';
import Chip from '@mui/material/Chip';
import { Theme, useTheme } from '@mui/material/styles';
import RequestRate from './sections/RequestRate';
import { GridRowsProp } from '@mui/x-data-grid';
import { CountResult, LogEntry, MethodCount } from 'src/interfaces/MethodCount';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const blockchainNetworks = [
  'Ethereum',
  'Ethereum Holesky',
  'Ethereum Sepolia',
  'Ethereum Goerli',
  'Polygon Mumbai',
  'Ethereum Goerli'
];
const dateRanges = [
  { key: 1, value: 1, display: 'Today' },
  { key: 2, value: 2, display: 'Yesterday' },
  { key: 3, value: 5, display: 'This Week' },
  { key: 4, value: 7, display: 'Last 7 Days' },
  { key: 5, value: 14, display: 'Last 14 Days' },
  { key: 6, value: 28, display: 'Last 28 Days' },
  { key: 7, value: 30, display: 'Last 30 Days' },
  { key: 8, value: 60, display: 'Last 60 Days' },
  { key: 9, value: 90, display: 'Last 90 Days' },
  { key: 10, value: 91, display: 'Last Quarter to date' },
  { key: 11, value: 365, display: 'Last 12 Months' },
  { key: 12, value: 0, display: 'This Year (Jan - Today)' },
];


function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const rows: GridRowsProp = [
  {
    id: '2ff1101a-13a6-46d2-90bd-7a049501b6f3',
    timestamp: 1717226438796,
    datetime: '2024-06-01T07:20:38.796Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 125,
    retries: 0,
    latency: 0,
    resKB: 41,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 998,
    msBeforeNext: 732,
    code: 200,
    attempts: [],
    method: 'eth_getTransactionCount',
    endDateTime: '2024-06-01T07:20:38.922Z',
    ms: 0.126
  },
  {
    id: '277539f1-cf6e-4d7a-af1f-e1c82d9f9ab2',
    timestamp: 1717226438528,
    datetime: '2024-06-01T07:20:38.528Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 116,
    retries: 0,
    latency: 0,
    resKB: 53,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 999,
    msBeforeNext: 1000,
    code: 200,
    attempts: [],
    method: 'eth_getBalance',
    endDateTime: '2024-06-01T07:20:38.779Z',
    ms: 0.251
  },
  {
    id: 'd49afdf4-2480-4998-84ce-a852adc3dceb',
    timestamp: 1717226438093,
    datetime: '2024-06-01T07:20:38.093Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 208,
    retries: 0,
    latency: 0,
    resKB: 775,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [ 'eth_blockNumber', 'eth_getTransactionByHash' ],
    isRateAllowed: false,
    remainingPoints: 997,
    msBeforeNext: 343,
    code: 200,
    attempts: [],
    method: 'batch',
    endDateTime: '2024-06-01T07:20:38.350Z',
    ms: 0.257
  },
  {
    id: '94786afe-1daa-42b0-8a3e-ad5ac4ec6ab3',
    timestamp: 1717226428561,
    datetime: '2024-06-01T07:20:28.561Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 141,
    retries: 0,
    latency: 0,
    resKB: 630,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 997,
    msBeforeNext: 544,
    code: 200,
    attempts: [],
    method: 'eth_getTransactionByHash',
    endDateTime: '2024-06-01T07:20:28.740Z',
    ms: 0.179
  },
  {
    id: 'c9a4838d-fcd5-48d5-b211-c70b1cdb5855',
    timestamp: 1717226428105,
    datetime: '2024-06-01T07:20:28.105Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 142,
    retries: 0,
    latency: 0,
    resKB: 39,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 999,
    msBeforeNext: 1000,
    code: 200,
    attempts: [],
    method: 'eth_getTransactionReceipt',
    endDateTime: '2024-06-01T07:20:28.265Z',
    ms: 0.16
  },{
    id: 'c045f016-f7d5-4260-bbe9-e880dad7c397',
    timestamp: 1717226427627,
    datetime: '2024-06-01T07:20:27.627Z',
    workerRegion: 'us-east-1',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 207,
    retries: 0,
    latency: 0,
    resKB: 43,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 996,
    msBeforeNext: 386,
    code: 200,
    attempts: [],
    method: 'eth_estimateGas',
    endDateTime: '2024-06-01T07:20:27.799Z',
    ms: 0.172
  }, {
    id: '7890d311-9c8f-41d5-910e-2577ebd855c5',
    timestamp: 1717226427475,
    datetime: '2024-06-01T07:20:27.475Z',
    workerRegion: 'ap-northeast-2',
    level: 'HTTP',
    ipAddress: '::ffff:127.0.0.1',
    chain: 'eth',
    network: 'goerli',
    cacheHit: false,
    agent: '',
    key: '6928439c764ae4f21c4f0b09cfb576d59a3ce5c3652326dd8316306c17feabff',
    'account-id': '78134953-c58f-418a-859f-aa15d22bf96e',
    'account-provider': '78134953-c58f-418a-859f-aa15d22bf96e',
    reqKB: 125,
    retries: 0,
    latency: 0,
    resKB: 40,
    worker: '10.0.0.153',
    product: 'high-throughput',
    methods: [],
    isRateAllowed: false,
    remainingPoints: 997,
    msBeforeNext: 538,
    code: 200,
    attempts: [],
    method: 'eth_getTransactionCount',
    endDateTime: '2024-06-01T07:20:27.610Z',
    ms: 0.135
  }];

  function countMethodsByDay(logs: GridRowsProp): CountResult {
    const counts: Record<string, Record<string, number>> = {};
    const datesSet: Set<string> = new Set();

    logs.forEach((log) => {
        const date = new Date(log.datetime).toISOString().split('T')[0];
        const method = log.method;

        if (!counts[date]) {
            counts[date] = {};
        }

        if (!counts[date][method]) {
            counts[date][method] = 0;
        }

        counts[date][method] += 1;
        datesSet.add(date);
    });

    const methodCounts: MethodCount[] = [];
    for (const date in counts) {
        for (const method in counts[date]) {
            methodCounts.push({
                date: date,
                method: method,
                count: counts[date][method],
            });
        }
    }

    const dates: string[] = Array.from(datesSet);

    return { methodCounts, dates };
}

function ObservabilityDashboard() {
  const theme = useTheme();
  const [blockchainNetwork, setBlockchainNetwork] = useState<string>("Ethereum");
  const [range, setRange] = useState<string>('Today');
  const [methodUsage, setMethodUsage] = useState<any>();

  useEffect(() => {
    let _methodUsage = [];

    const result = countMethodsByDay(rows);

    for(const method of result.methodCounts){
      _methodUsage.push({ data: [method.count], label: method.method, stack: 'stack1' })
    }

    setMethodUsage({data: _methodUsage, labels: result.dates});

  }, []);

  useEffect(() => {

    console.log(methodUsage);
  }, [methodUsage]);

  return (
    <>
      <Helmet>
        <title>RPC Load Balancer Observability</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Box
        display="flex"
        alignItems="right"
        justifyContent="space-between"
        sx={{
          pb: 3
        }}
      >
        <Grid container>
          <Grid xs={7} md={7} item>
            <Typography variant="subtitle2">&nbsp;</Typography>
          </Grid>
          <Grid xs={5} md={5} item>
            <div>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="demo-multiple-chip-label">
                  Blockchain Networks
                </InputLabel>
                <Select
                  size='small'
                  labelId="blockchain-network-simple-select-label"
                  id="blockchain-network-simple-select"
                  value={blockchainNetwork}
                  label="Blockchain Network"
                  onChange={(e) => {
                    setBlockchainNetwork(e.target.value);
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
                      <Chip key={selected} label={selected} />
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {blockchainNetworks.map((blockchainNetwork) => (
                    <MenuItem
                      key={blockchainNetwork}
                      value={blockchainNetwork}
                      style={{ fontWeight: theme.typography.fontWeightMedium }}
                    >
                      {blockchainNetwork}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>With label + helper text</FormHelperText>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-multiple-chip-label">Range</InputLabel>
                <Select
                  size='small'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={range}
                  label="Date Range"
                  onChange={(e) => {
                    setRange(e.target.value);
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
                      <Chip key={selected} label={selected} />
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {dateRanges.map((_range) => (
                    <MenuItem
                      key={_range.key}
                      value={_range.value}
                      style={{ fontWeight: theme.typography.fontWeightMedium }}
                    >
                      {_range.display}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>With label + helper text</FormHelperText>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid xs={3} md={3} item>
            <RequestRate />
          </Grid>
          <Grid xs={9} md={9} item>
            <RequestDistributionTrend />
          </Grid>
          <Grid xs={6} md={6} item>
            <ResponseTime />
          </Grid>
          <Grid xs={6} md={6} item>
            <MethodUsage data={methodUsage}/>
          </Grid>
          <Grid xs={12} md={12} item>
            <EntriesTable rows={rows}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ObservabilityDashboard;
