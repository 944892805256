import React from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { CryptoUtils } from 'src/class/CryptoUtils';
import Loading from './components/Loading';
import useAuth from './hooks/useAuth';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>; // Change RouterProps to any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { user, isLoading } = useAuth();


  if (isLoading) {
    return <Loading isLoading={true} />; // You need to define the Loading component
  }


  if (user) {
    return (
      <Component {...rest} />
    );
  }

  const hashed = CryptoUtils.hash(JSON.stringify({title: "Login", message: "Please login."}));

  // Redirect if there is no user
  return <Navigate to={`/?m=${hashed}&msg=Please login&t=Login`} replace={true} />;
};

export default PrivateRoute;
