import React from "react";
import logoLightImg from "../../images/backpac-icon-transparent.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  width?: string;
  height?: string;
}

const BackpacIcon: React.FC<LogoProps> = ({
  img = logoLightImg,
  imgLight = logoLightImg,
  className = "",
  width = "35px",
  height = "35px"
}) => {
  return (
    <img
    className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
    src={img}
    alt="Logo"
    width={width}
    height={height}
  />
  );
};

export default BackpacIcon;
