import { useRef, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Avatar
} from '@mui/material';
import { User } from 'src/models/user';
import useAuth from 'src/hooks/useAuth';
import { Hearing, Apps } from '@mui/icons-material';;
import { styled } from '@mui/material/styles';
import CloudQueueSharpIcon from '@mui/icons-material/CloudQueueSharp';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import './user-icon.css';
const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function getInitials(name) {
  const names = name.split(' ');
  return names.map(name => name[0].toUpperCase()).join('');
}

function UserIcon({ name }) {
  //console.log(typeof name);
  const initials = typeof name !== "undefined" ? getInitials(name) : "";

  return (
    <div className="user-icon">
      {initials}
    </div>
  );
}

function HeaderUserbox() {
  const { user, logoutUser } = useAuth();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [_user, setUser] = useState<User>(user);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const subscription = (user: User): string => {
    if (typeof user === 'undefined' || user === null) return '';
    if (user.plan === 'backpac' && typeof user.subscriptions !== "undefined") {
      const subscriptions = JSON.parse(user.subscriptions as string);
      return subscriptions[0].plan;
    }
    return user.plan;
  };
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {typeof user !== 'undefined' && user !== null && user.provider === 'backpac' ? (
          <Avatar variant="circular" alt={user.name} src={user.picture} />
        ) : (
          <UserIcon
            name={
              typeof user !== 'undefined' && user !== null
                ? user.name
                : undefined
            }
          />
        )}

        {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {typeof user !== 'undefined' && user !== null ? user.name : ''}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {subscription(user)}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
        {typeof user !== 'undefined' && user !== null && user.provider === 'backpac' ? (
          <Avatar variant="rounded" alt={user.name} src={user.picture} />
        ) : (
          <UserIcon
            name={
              typeof user !== 'undefined' && user !== null
                ? user.name
                : undefined
            }
          />
        )}
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {typeof user !== 'undefined' && user !== null ? user.name : ''}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {typeof user !== 'undefined' && user !== null
                ? user.organization_name
                : ''}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/management/apps" component={NavLink}>
            <Apps fontSize="small" />
            <ListItemText primary="Applications" />
          </ListItem>
          <ListItem button to="/management/providers" component={NavLink}>
            <CloudQueueSharpIcon fontSize="small" />
            <ListItemText primary="RPC Providers" />
          </ListItem>
          <ListItem button to="/lb/listeners" component={NavLink}>
            <Hearing fontSize="small" />
            <ListItemText primary="Listeners" />
          </ListItem>
          <ListItem button to="/lb/target-groups" component={NavLink}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Target Groups" />
          </ListItem>
          <ListItem
            button
            onClick={() => window.open('https://docs.backpac.xyz/', '_blank')}
          >
            <DescriptionOutlinedIcon fontSize="small" />
            <ListItemText primary="Documentation" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={logoutUser}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
