import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
import logoLightImg from "../../images/backpac-icon-transparent.png";


function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* <CircularProgress size={64} disableShrink thickness={3} /> */}
      <img
    className="bounce-image"
    src={logoLightImg}
    alt="Logo"
    height={151}
    width={144}
  />
    </Box>
  );
}

export default SuspenseLoader;
